import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  TextField,
  Link,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { t } from "i18next";
import ReactHtmlParser from "html-react-parser";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { updateUserCouponCode } from "../../store/Actions/userAction";

export const PromotionCodeCard = ({ globalEvent, setGlobalEvent }) => {
  const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);
  const dispatch = useDispatch();
  const handleAddPromoCodeClick = () => {
    setShowPromoCodeInput(true);
  };

  const handleClose = () => {
    setShowPromoCodeInput(false);
  };

  const couponCodeActivated = useSelector((state) => state.user.couponCodeActivated);

  useEffect(() => {
    if (couponCodeActivated === true) {
      setGlobalEvent({
        type: "showToast",
        data: {
          message: t("profile.promotion_code.success_message"),
          toastType: "success",
        },
      });
    } else if(couponCodeActivated === false) {
      setGlobalEvent({
        type: "showToast",
        data: {
          message: t("errorResponse.invalid_promocode"),
          toastType: "error",
        },
      });
    }
  }, [couponCodeActivated]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    dispatch(updateUserCouponCode(data.promotion_code));
    setShowPromoCodeInput(false);
  };

  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent>
        {!showPromoCodeInput && (
          <Link
            className="secondary-blue-text-link"
            sx={{
              color: "primary.light",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={handleAddPromoCodeClick}
          >
            + {t("common.set_promotion_code")}
          </Link>
        )}

        {showPromoCodeInput && (
          <>
            <Grid container component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid xs={12} item>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t("profile.promotion_code.title")}
                  <InfoOutlinedIcon
                    sx={{ fontSize: "inherit", strokeWidth: "2", ml: 3 }}
                  />
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box>
                  <TextField
                    key="promotion_code"
                    className="fullwidth-field"
                    label={ReactHtmlParser(
                      t("profile.promotion_code.fieldName")
                    )}
                    type="text"
                    {...register("promotion_code", {
                      required: ReactHtmlParser(t("errors.field.required")),
                    })}
                  />

                  {errors.promotion_code && (
                    <span role="alert">{errors.promotion_code.message}</span>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: "flex",
                  px: 2,
                  mt: 5,
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  className="secondary-blue-btn"
                  variant="contained"
                  sx={{
                    height: "50px",
                    width: "100%",
                  }}
                >
                  {t("profile.promotion_code.btnAdd")}
                  <ArrowForwardIcon
                    sx={{ fontSize: "inherit", strokeWidth: "1", ml: 3 }}
                  />
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 5,
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="text"
                  sx={{
                    minWidth: "50px",
                    width: "50px !important",
                    height: "50px",
                    p: 0,
                    boxShadow: "0px 0px 10px 0.2px #0000001A",
                    borderRadius: "32px",
                  }}
                >
                  <CloseIcon />
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PromotionCodeCard;
