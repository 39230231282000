import { Button, TextField, Typography, Box, Stack } from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import {
  createPaymentIntent,
  setAmount,
  setPaymentDetails,
} from "../../store/Slices/paymentSlice";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { HandleNextContext } from "../Layout/PaymentLayout";
import { useForm } from "react-hook-form";
import { loadCountries } from "../../store/Actions/countryAction";
import CountrySelect from "../Commons/CountrySelect";
import {
  getCurrentUser,
  setUserCountry,
  updateBillingAddress,
} from "../../store/Actions/userAction";
import { isEmptyObject } from "../../store/helper";
import { useNavigate } from "react-router-dom";
import { showSnackbar } from "../../store/Slices/snackbarSlice";

const PersonalDataForm = ({ globalEvent, setGlobalEvent }) => {
  const { handleNext } = useContext(HandleNextContext);
  const dispatch = useDispatch();
  const { plan } = useSelector((state) => state.payment);
  const { countries } = useSelector((state) => state.country);
  const { user, loading: userLoading } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    dispatch(loadCountries());
    if (isEmptyObject(user) === true) {
      dispatch(getCurrentUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (user && userLoading === false) {
      reset({
        name: user.billingAddress?.name ?? "",
        country: user.billingAddress?.country ?? "",
        city: user.billingAddress?.city ?? "",
        postalCode: user.billingAddress?.postalCode ?? "",
        street: user.billingAddress?.street ?? "",
        number: user.billingAddress?.number ?? "",
      });
    }
  }, [user, userLoading, reset]);

  const onCountryChanged = (value) => {
    setUserCountry(value);

    reset({
      country: value,
    });
  };

  const onSubmit = async (data) => {
    const paymentDetails = {
      name: data.name,
      country: data.country,
      postalCode: data.postalCode,
      city: data.city,
      street: data.street,
      number: data.number,
    };
    dispatch(setPaymentDetails(paymentDetails));
    dispatch(updateBillingAddress(paymentDetails));
    const actionResult = await dispatch(createPaymentIntent(plan));
    if (createPaymentIntent.fulfilled.match(actionResult)) {
      if (actionResult.payload.amount) {
        dispatch(setAmount(actionResult.payload.amount));
      }

      if (actionResult.payload.redirectToDashboard) {
        //dispatch(
        //  showSnackbar({
        //    message: t("payment.redirecting_dashboard"),
        //    severity: "success",
        //  })
        //);

        setGlobalEvent({
          type: "showToast",
          data: {
            message: t("payment.redirecting_dashboard"),
            toastType: "info",
          },
        });

        navigate("/profile");
      } else {
        handleNext("step2");
      }
    } else {
      console.error(
        "Failed to create payment intent:",
        actionResult.error.message
      );
    }
  };

  return (
    <div>
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
            width: "100%",
            textAlign: "start",
          }}
        >
          {t("payment.details.title")}
        </Typography>
        <Typography
          variant="body3"
          sx={{
            mb: 4,
            width: "100%",
            textAlign: "start",
          }}
        >
          {ReactHtmlParser(t("payment.details.description"))}
        </Typography>
      </Box>
      <Stack
        direction="column"
        spacing={6}
        sx={{
          mt: { xs: 0, sm: 4 },
          width: { xs: "100%", md: "auto" },
          justifyContent: "center",
        }}
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={t("payment.details.field.fullname")}
            name="name"
            autoComplete="name"
            autoFocus
            {...register("name", {
              required: t("payment.details.validation.fullname_required"),
            })}
            sx={{
              color: "primary.main",
              fontWeight: 600,
              borderRadius: "50px",
              "& .MuiInputBase-root": { borderRadius: "50px" },
            }}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
          />

          <CountrySelect
            register={register}
            errors={errors}
            countries={countries}
            defaultValue={
              user && user.billingAddress ? user.billingAddress.country : ""
            }
            onCountryChanged={onCountryChanged}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="postalCode"
            label={t("payment.details.field.postalCode")}
            type="text"
            id="postalCode"
            autoComplete="postalCode"
            {...register("postalCode", {
              required: t("payment.details.validation.postalcode_required"),
              validate: {
                validPostalCode: (value) => {
                  if (watch("country") === "HU") {
                    return /^(?:\d{4})$/.test(value) || t("errors.field.invalidPostalCode");
                  } else {
                    return true;
                  }
                }
              },
            })}
            sx={{
              color: "primary.main",
              fontWeight: 600,
              borderRadius: "50px",
              "& .MuiInputBase-root": { borderRadius: "50px" },
            }}
            error={!!errors.postalCode}
            helperText={errors.postalCode && errors.postalCode.message}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="city"
            label={t("payment.details.field.city")}
            type="text"
            id="city"
            autoComplete="city"
            {...register("city", {
              required: t("payment.details.validation.city_required"),
            })}
            error={!!errors.city}
            sx={{
              color: "primary.main",
              fontWeight: 600,
              borderRadius: "50px",
              "& .MuiInputBase-root": { borderRadius: "50px" },
            }}
            helperText={errors.city && errors.city.message}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="street"
            label={t("payment.details.field.street")}
            type="text"
            id="street"
            autoComplete="street"
            {...register("street", {
              required: t("payment.details.validation.street_required"),
            })}
            error={!!errors.street}
            sx={{
              color: "primary.main",
              fontWeight: 600,
              borderRadius: "50px",
              "& .MuiInputBase-root": { borderRadius: "50px" },
            }}
            helperText={errors.street && errors.street.message}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="number"
            label={t("payment.details.field.street_number")}
            type="text"
            id="number"
            autoComplete="number"
            {...register("number", {
              required: t("payment.details.validation.streetnumber_required"),
            })}
            error={!!errors.street}
            sx={{
              color: "primary.main",
              fontWeight: 600,
              borderRadius: "50px",
              "& .MuiInputBase-root": { borderRadius: "50px" },
            }}
            helperText={errors.number && errors.number.message}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, boxShadow: "none", py: 2 }}
          >
            {t("payment.details.btn_next")}
          </Button>
        </Box>
      </Stack>
    </div>
  );
};

export default PersonalDataForm;
