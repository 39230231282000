import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../api';

const rules = [
    { match: slug => slug === 'asset-list', result: 'unrecorded-asset' },
    { match: slug => slug === 'contact-list', result: 'unrecorded-beneficiary' },
    { match: slug => slug.startsWith('add-new-asset'), result: 'asset-creation-end' },
  ];
  
  const resolveSlug = (slug) => {
    const rule = rules.find(r => r.match(slug));
    return rule ? rule.result : null; 
  };

export const fetchHints = createAsyncThunk(
    'hints/fetchHints',
    async (slug, thunkAPI) => {
      try {
        const requestSlug = resolveSlug(slug);

        if(requestSlug) {
            return await fetchWithAuth(`hint/${requestSlug}`);
        } else {
            return null;
        }
      } catch (error) {
        return thunkAPI.rejectWithValue('Failed to fetch hints');
      }
    }
  );

  const hintSlice = createSlice({
    name: 'hints',
    initialState: {
      hints: null,
      loading: false,
      error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchHints.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.hints = null;
        })
        .addCase(fetchHints.fulfilled, (state, action) => {
          state.loading = false;
          state.hints = action.payload;
        })
        .addCase(fetchHints.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
          state.hints = null;
        });
    },
  });
  
  export default hintSlice.reducer;