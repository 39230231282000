import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithAuth } from "../api";

const initialState = {
  exchangeRates: {},
  status: "idle",
  error: null,
};

export const fetchExchangeRates = createAsyncThunk(
  "hats/fetchHats",
  async (_, { rejectWithValue }) => {
    const MAX_RETRIES = 2;
    let attempt = 0;
    let success = false;
    let exchangeRates = null;

    while (attempt <= MAX_RETRIES && !success) {
      try {
        const response = await fetchWithAuth("exchange-rates");

        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.statusText}`);
        }

        const data = await response.json();
        exchangeRates = data.exchangeRates;

        if (typeof localStorage !== "undefined") {
          const timestampedData = {
            data: exchangeRates,
            timestamp: Date.now(),
          };
          localStorage.setItem("exchangeRates", JSON.stringify(timestampedData));
        } else {
          console.warn("localStorage is not available.");
        }

        success = true;
      } catch (error) {
        attempt += 1;
        console.warn(`Attempt ${attempt} failed: ${error.message}`);
        if (attempt > MAX_RETRIES) {
          return rejectWithValue(error.message);
        }
      }
    }

    return exchangeRates;
  }
);

export const getCachedExchangeRates = (maxAge = 60 * 60 * 1000) => {
  if (typeof localStorage === "undefined") {
    return null;
  }

  const cachedData = localStorage.getItem("exchangeRates");
  
  if (!cachedData) {
    return null;
  }

  const { data, timestamp } = JSON.parse(cachedData);

  if (Date.now() - timestamp > maxAge) {
    localStorage.removeItem("exchangeRates");
    return null;
  }

  return data;
};

const exchangeRatesSlice = createSlice({
  name: "exchangeRates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchangeRates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExchangeRates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.exchangeRates = action.payload;
      })
      .addCase(fetchExchangeRates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default exchangeRatesSlice.reducer;
