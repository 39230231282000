import { fetchHats } from "../Slices/hatsSlice";
import { resetPlan } from "../Slices/paymentSlice";
import { fetchWithAuth, simpleFetchWithAuth } from "../api";

export const getCurrentUser = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_CURRENT_USER_REQUEST" });
    try {
      const data = await fetchWithAuth("/users/current");
      dispatch(fetchHats());
      dispatch({ type: "FETCH_CURRENT_USER_SUCCESS", payload: data.user });
    } catch (error) {
      dispatch({ type: "FETCH_CURRENT_USER_FAILURE", payload: error });
    }
  };
};

export const updateBillingAddress = (billingData) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_CURRENT_USER_BILLING_ADDRESS_REQUEST" });
    try {
      const response = await fetchWithAuth("/users/current", {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user: {
            billingAddress: billingData,
          },
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        dispatch({
          type: "UPDATE_CURRENT_USER_BILLING_ADDRESS_SUCCESS",
          payload: responseData.user,
        });
      } else {
        throw new Error("Failed to update billing address");
      }
    } catch (error) {
      dispatch({
        type: "UPDATE_CURRENT_USER_BILLING_ADDRESS_FAILURE",
        payload: error.message,
      });
    }
  };
};

export const updateUserMarketingPreference = (boolVal) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_CURRENT_USER_MARKETING_PREFERENCE_REQUEST" });
    try {
      const response = await fetchWithAuth("/users/current", {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user: {
            emailMarketing: boolVal,
          },
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        dispatch({
          type: "UPDATE_CURRENT_USER_MARKETING_PREFERENCE_SUCCESS",
          payload: responseData.user,
        });
      } else {
        throw new Error("Failed to update marketing preference");
      }
    } catch (error) {
      dispatch({
        type: "UPDATE_CURRENT_USER_MARKETING_PREFERENCE_FAILURE",
        payload: error.message,
      });
    }
  };
};

export const updateTwoFactorAuth = (boolVal) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_CURRENT_USER_2FA_REQUEST" });
    try {
      const response = await fetchWithAuth("/users/current", {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user: {
            twoFactorEnabled: boolVal,
          },
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        dispatch({
          type: "UPDATE_CURRENT_USER_2FA_SUCCESS",
          payload: responseData.user,
        });
      } else {
        throw new Error("Failed to update 2fa");
      }
    } catch (error) {
      dispatch({
        type: "UPDATE_CURRENT_USER_2FA_FAILURE",
        payload: error.message,
      });
    }
  };
};

export const setRegConsentToMarketing = (boolVal) => {
  return async (dispatch) => {
    dispatch({ type: "SET_REG_CONSENT_TO_MARKETING", payload: boolVal });
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("chid");
    dispatch(resetPlan());
    dispatch({ type: "USER_LOGOUT" });
  };
};

export const setUserCountry = (countryCode) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_CURRENT_USER_COUNTRY",
      payload: countryCode,
    });
  };
};

export const handleProfileImageUpload = (file, onFileUploaded) => {
  return async (dispatch) => {
    dispatch({ type: "UPLOAD_PROFILE_IMAGE_REQUEST" });
    try {
      let formdata = new FormData();
      formdata.append("profileImage", file, file?.name);

      const response = await fetchWithAuth("users/current/profile-image", {
        method: "POST",
        body: formdata,
      });

      if (onFileUploaded) {
        onFileUploaded(response);
      }

      dispatch({ type: "UPLOAD_PROFILE_IMAGE_SUCCESS", payload: response });
    } catch (error) {
      dispatch({ type: "UPLOAD_PROFILE_IMAGE_FAILURE", payload: error });
    }
  };
};

export const recoverProfile = () => {
  return async (dispatch) => {
    try {
      const response = await fetchWithAuth("users/current/recover", {
        method: "POST",
      });

      dispatch({ type: "RECOVER_PROFILE_SUCCESS", payload: response });
      dispatch(getCurrentUser());
    } catch (error) {
      dispatch({ type: "RECOVER_PROFILE_FAILURE", payload: error });
    }
  };
};

export const updateUserCouponCode = (couponCode) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_CURRENT_USER_COUPON_CODE_REQUEST" });
    try {
      const response = await simpleFetchWithAuth("users/current", {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user: {
            couponCode: couponCode,
          },
        }),
      });

      if (response?.status === 400 && response.ok === false) {
        dispatch({
          type: "UPDATE_CURRENT_USER_COUPON_CODE_FAILURE",
          payload: { message: "errorResponse.invalid_promocode" },
        });
      } else {

        if (response.ok) {
          dispatch({
            type: "UPDATE_CURRENT_USER_COUPON_CODE_SUCCESS"
          });
        } else {
          throw new Error("Failed to update coupon code");
        }
      }
    } catch (error) {
      dispatch({
        type: "UPDATE_CURRENT_USER_COUPON_CODE_FAILURE",
        payload: error.message,
      });
    }
  };
};
