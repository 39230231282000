import {
  Typography,
  Grid,
  Box,
  Stack,
  Card,
  CardContent,
  Button,
  TextField,
  Fade,
  Chip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState, useEffect } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { formatDate } from "../../store/helper";
import { saveEntry } from "../../store/Actions/timeCapsuleEntriesActions";

export const GamificationQuestionForm = ({
  question,
  onSubmitSuccess,
  globalEvent,
  setGlobalEvent,
}) => {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState("");
  const [hasPlan, setHasPlan] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    mode: "onBlur",
  });

  const answerValue = watch("answer");
  useEffect(() => {
    if (setGlobalEvent) {
      if (answerValue?.length > 0)
        setGlobalEvent({ type: "checkPlan", data: { type: "diaryItems" } });
    }
  }, [answerValue]);

  const skipAnswer = (data) => {
    //dispatch(
    //  saveEntry(null, {
    //    diaryItem: {
    //      title: question.text,
    //      text: " ",
    //      diaryQuestionId: question.id,
    //      topic: question.topic,
    //    },
    //  })
    //);

    if (onSubmitSuccess) {
      onSubmitSuccess({});
    }

    handleReset();
  };

  const onSubmit = (data) => {
    dispatch(
      saveEntry(null, {
        diaryItem: {
          title: question.text,
          text: data.answer,
          diaryQuestionId: question.id,
          topic: question.topic,
        },
      })
    );

    if (onSubmitSuccess) {
      onSubmitSuccess(data);
    }

    handleReset();
  };

  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const handleReset = () => {
    setHasPlan(false);
    reset();
  };

  useEffect(() => {
    //if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
    //console.log("------ Global Event ------ GamificationQuestionForm");
    //console.log(globalEvent);
    //console.log("------------");
    //}

    if (globalEvent?.type == "responseCheckPlan") {
      if (globalEvent?.data.type == "diaryItems") {
        setHasPlan(true);
      }
    }
  }, [globalEvent]);

  return (
    <>
      <Card
        sx={{
          borderRadius: "16px",
          px: 2,
          pt: { xs: 1, sm: 2 },
          mt: 2,
          boxShadow: "4px 4px 20px 0px #0000001A",
          bgcolor: "rgba(255, 242, 126, 0.25)",
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" sx={{ mb: 2 }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "rgba(43, 54, 116, 0.6)",
                  }}
                >
                  <CalendarMonthOutlinedIcon />
                  {formatDate(question?.createdAt)}
                </Typography>

                <Box sx={{ flexGrow: 1 }} />

                <Chip
                  label={question?.topic}
                  sx={{
                    color: "rgba(43, 54, 116, 1)",
                    backgroundColor: "rgba(255, 242, 126, 1)",
                    fontSize: "10px",
                    fontWeight: 700,
                    fontFamily: "Sora, sans-serif",
                    boxShadow: "10px",
                  }}
                />
              </Stack>

              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {t("timeCapsule.question.title")}
                </Typography>
                <Typography
                  variant="body3"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    mt: 1,
                  }}
                >
                  {question?.text}
                </Typography>

                <Box
                  sx={{ mt: 1 }}
                  component="form"
                  onSubmit={handleSubmit(onSubmit, onError)}
                >
                  <Fade in={true} timeout={300}>
                    <TextField
                      label={t("timeCapsule.question.answerPlaceholder")}
                      className="fullwidth-textarea"
                      {...register("answer", { required: true })}
                      error={!!errors.answer}
                      multiline
                      rows={4}
                      sx={{
                        marginTop: "15px !important",
                        bgcolor: "#fff",
                        borderRadius: "24px",
                        ".MuiInputBase-inputMultiline": {
                          pt: 2,
                          pl: 2.5,
                        },
                      }}
                    />
                  </Fade>
                  <>
                    <Fade in={true} timeout={300}>
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        sx={{
                          width: "100%",
                          alignItems: "center",
                          mt: { md: 3 },
                        }}
                      >
                        <Button
                          variant="text"
                          className="secondary-blue-text-btn"
                          sx={{
                            bgcolor: "main.light",
                            height: "50px",
                            fontSize: 12,
                            color: "#1542B9",
                            fontWeight: 500,
                          }}
                          onClick={skipAnswer}
                        >
                          {t("timeCapsule.question.skipAnswer")}
                        </Button>

                        <Box sx={{ flexGrow: 1 }} />

                        <Button
                          variant="contained"
                          className="secondary-blue-btn"
                          type="submit"
                          sx={{
                            height: "52px",
                            width: "auto !important",
                            minWidth: "auto !important",
                          }}
                        >
                          {t("timeCapsule.question.submitBtn")}
                          <ArrowForwardIcon
                            sx={{
                              fontSize: "inherit",
                              strokeWidth: "1",
                              ml: 3,
                            }}
                          />
                        </Button>
                      </Stack>
                    </Fade>
                  </>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default GamificationQuestionForm;
