import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Box, Stack } from "@mui/material";
import { t } from "i18next";
import DefaultLayout from "../Layout/DefaultLayout";
import EntryList from "./EntryList";
import GamificationEntryForm from "./GamificationEntryForm";
import GamificationQuestionForm from "./GamificationQuestionForm";
import WhoSees from "./WhoSees";
import {
  fetchQuestion,
  fetchTimeCapsuleEntries,
  fetchTimeCapsuleImages,
} from "../../store/Actions/timeCapsuleEntriesActions";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";
import BannerDisplayer from "../Banner/BannerDisplayer";
import { fetchContacts } from "../../store/Actions/contactAction";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import NoAccess from "../Commons/NoAccess";
import LimitReached from "../Commons/LimitReached";

const Gamification = ({ globalEvent, setGlobalEvent, title }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { items, question, loading, error } = useSelector(
    (state) => state.timeCapsule
  );
  const { items: cItems, loading: cLoading } = useSelector(
    (state) => state.contact
  );
  const [whoSeesUsers, setWhoSeesUsers] = useState([]);
  const chid = useMemo(() => localStorage.chid, []);
  const { access, status: hatsStatus } = useSelector((state) => state.hats);

  useEffect(() => {
    dispatch(fetchTimeCapsuleEntries());
    dispatch(fetchTimeCapsuleImages());
    dispatch(fetchQuestion());
    if (cItems?.length === 0 && cLoading === false) {
      dispatch(fetchContacts());
    }
  }, [dispatch]);

  useEffect(() => {
    const usrList = cItems?.map((cItem) => {
      let date = "";
      let added = false;
      if (cItem.accessTypeDiaryItems === "instant") {
        date = t("contact.accesstype.instant");
        added = true;
      } else if (cItem.accessTypeDiaryItems === "timed") {
        date = "betekintés: " + cItem.accessAtDiaryItems;
        added = true;
      } else if (cItem.accessTypeDiaryItems === "onDeath") {
        date = t("contact.accesstype.ondeath");
        added = true;
      }
      return {
        id: cItem.id,
        name: `${cItem.firstName} ${cItem.lastName}`,
        date,
        type: cItem.accessTypeDiaryItems,
        added,
      };
    });

    setWhoSeesUsers(usrList);
  }, [cItems]);

  const handleSubmitSuccess = useCallback(() => {
    dispatch(fetchQuestion());
  }, [dispatch]);

  if (
    access?.accessDiaryItems === false &&
    access?.accessDiaryItemsOnDeath === false &&
    items?.length === 0
    && error?.length > 0 
  ) {
    return (
      <NoAccess
        globalEvent={globalEvent}
        setGlobalEvent={setGlobalEvent}
        title={title}
      />
    );
  } else {
    return (
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
        <BannerDisplayer position={"top"} page={"time_capsule"} />

        <LimitReached limitType="diaryItems" count={items?.length} />

        <Box className="my-container">
          <Grid sx={{ mt: { xs: 0, sm: 6 }, mb: 20 }} container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6">{t("timeCapsule.title")}</Typography>
              <Typography variant="body3" sx={{ fontSize: "14px" }}>
				  {(!chid || chid === "null") && (<>{t("timeCapsule.description")}</>)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={7} sx={{ px: { md: 3 } }}>
              <Stack
                direction={"column"}
                spacing={2}
                alignItems={"left"}
                sx={{ mb: 6 }}
              >
                {(!chid || chid === "null") && (
                  <>
                    <GamificationEntryForm
                      defaultTitle=""
                      isNewEntry={true}
                      globalEvent={globalEvent}
                      setGlobalEvent={setGlobalEvent}
                    />
                    {question && (question.length > 0 || question?.id) && (
                      <GamificationQuestionForm
                        question={question}
                        onSubmitSuccess={handleSubmitSuccess}
                        globalEvent={globalEvent}
                        setGlobalEvent={setGlobalEvent}
                      />
                    )}
                  </>
                )}
                <EntryList
                  entries={items}
                  isLoading={loading}
                  error={error}
                  entryType={"timeCapsule"}
				  displayMenu={(!chid || chid == "null")}
                />
              </Stack>
              <BannerDisplayer position={"bottom"} page={"time_capsule"} />
            </Grid>

            <Grid item xs={12} md={5} sx={{ px: { md: 3 } }}>
              <Stack direction={"column"} spacing={2} alignItems={"left"}>
                {(!chid || chid === "null") && (
                  <WhoSees
                    globalEvent={globalEvent}
                    setGlobalEvent={setGlobalEvent}
                    users={whoSeesUsers}
                    suffix={"DiaryItems"}
                  />
                )}
                <HelpCard />
                <ReferralCard />
                <BannerDisplayer position={"right"} page={"time_capsule"} />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DefaultLayout>
    );
  }
};

export default Gamification;
