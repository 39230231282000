import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  Link,
} from "@mui/material";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchPlans } from "../../store/Actions/planAction";
import { getCurrentUser } from "../../store/Actions/userAction";
import { useNavigate } from "react-router-dom";
import { EditButton } from "./EditButton";
export const SubscriptionDataCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading: userLoading } = useSelector((state) => state.user);
  const { plans, loading: planLoading } = useSelector((state) => state.plan);

  const currentPlan = plans.find(
    (plan) => plan.name === user?.subscription?.planName
  );
  const editTarget = "/subscription";

  const getExpirationDate = () => {
    const expiration = user.subscription.expiration;
    if (expiration !== null) {
      const dateObject = new Date(expiration);
      const year = dateObject.getUTCFullYear();
      const month = dateObject.getUTCMonth(); // Note: Month is zero-based (0 = January, 11 = December)
      const day = dateObject.getUTCDate();
      const formattedDate = new Intl.DateTimeFormat("hu-hu", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateObject);
      return formattedDate;
    } else {
      return null;
    }
  };

  useEffect(() => {
    dispatch(fetchPlans());
    if (!user.subscription) {
      dispatch(getCurrentUser());
    }
  }, []);

  useEffect(() => {
    //console.log("plans", plans);
  }, [plans]);

  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 700,
                }}
              >
                {t("profile.subscription.title")}
              </Typography>

              <Box sx={{ mt: 3 }}>
                {currentPlan &&
                userLoading === false &&
                planLoading === false ? (
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      {currentPlan?.localized?.name ?? currentPlan.name}
                    </Typography>
                    <Typography variant="body2">
                      {`${currentPlan.price} Ft`}{" "}
                      {currentPlan.billingCycle + "" == "null"
                        ? t("billingCycle." + currentPlan.billingCycle)
                        : currentPlan.price > 0
                        ? "/" + t("billingCycle." + currentPlan.billingCycle)
                        : ""}
                    </Typography>
                    {user.subscription.expiration != null ? (
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        {t("profile.subscription.expiration")}{" "}
                        {getExpirationDate()}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Typography variant="body2">
                    {t("profile.subscription.noPlan")}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {" "}
            {currentPlan?.name != "lifetime" && (
              <EditButton target={editTarget} />
            )}
          </Grid>
        </Grid>
        <Button
          className="secondary-blue-text-btn"
          variant="text"
          sx={{
            mt: 2,
            pl: 0,
            fontSize: "14px",
            lineHeight: "17.64px",
          }}
          onClick={() => {
            navigate("/billing");
          }}
        >
          {t("profile.subscription.listLink")}
        </Button>
      </CardContent>
    </Card>
  );
};
