import { Typography, Box } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";

const stripePromise = loadStripe(
  process.env.STRIPE_PUBLISHABLE_KEY
);

const PaymentForm = () => {
  const navigate = useNavigate();

  const clientSecret = useSelector(
    (state) => state.payment.paymentIntentSecret
  );
  const options = {
    clientSecret,
    placeholder: "",
    locale: i18n.language || "hu",
  };

  const selectedPlanName = useSelector((state) => state.payment.plan);
  const plans = useSelector((state) => state.plan.plans);
  const selectedPlanDetails = plans.find(
    (plan) => plan.name === selectedPlanName
  );
  const couponPrice = Math.floor(
    useSelector((state) => state.payment.amount) / 100
  );

  useEffect(() => {
    if (clientSecret == null && selectedPlanName == null) {
      navigate("/profile");
    }

    if (clientSecret == null) {
      navigate("/payment");
    }
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <Typography component="h1" variant="h5">
        {t("payment.stripe.title")}
      </Typography>
      <Typography variant="body3">
        {t("payment.stripe.selectedPackage")}:{" "}
        <strong>
          {selectedPlanDetails
            ? `${selectedPlanDetails.name} (${
                selectedPlanDetails.price
              } Ft / ${t(`billingCycle.${selectedPlanDetails.billingCycle}`)})`
            : t("payment.stripe.noPackageSelected")}
        </strong>
      </Typography>
      {selectedPlanDetails.price !== couponPrice && (
        <Typography variant="body3" sx={{ fontWeight: 700 }}>
          {t("payment.stripe.coupon")} {couponPrice}{" "}
          {t("payment.stripe.currency")}
        </Typography>
      )}
      <Elements stripe={stripePromise} options={options}>
        <StripePaymentForm />
      </Elements>
    </Box>
  );
};

export default PaymentForm;
