import { useRef, useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import CountrySelect from "../Commons/CountrySelect";
import { useDispatch, useSelector } from "react-redux";
import { loadCountries } from "../../store/Actions/countryAction";

export const AddDataStep1 = ({ globalEvent, setGlobalEvent, title, isBackBtnVisible = true }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  const currentYear = dayjs().year();

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const [change, setChange] = useState(false);
  const [phone, setPhone] = useState("+36");
  const country = useRef("HU");
  const handleCountryChange = (value) => {
    country.current = value;
    setChange(!change);
  };

  const handlePhoneChange = (newPhone) => {
    setPhone(newPhone);
  };

  const initialData = useRef({});

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data, e) => {
    if (isDataChanged(data) || location.pathname == "/") {
      if(phoneChanged(data)) {
        doSaveUserData(e, collectData(data, e)); 
      } else {
        setGlobalEvent({ type: "doSaveUserDataFromProfile", data: collectData(data, e) });
        
        navigate("/profile", { replace: true });
      }
    } else {
      navigate("/profile", { replace: true });
    }
  };

  const phoneChanged = (data) => {
    const currentData = collectData(data);

    return currentData.user.phone !== initialData.current.phone;
  }


  const isDataChanged = (data) => {
    const currentData = collectData(data);
    return (
      JSON.stringify(currentData.user) !== JSON.stringify(initialData.current)
    );
  };

  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const [yearOfBirth, setYearOfBirth] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const { countries } = useSelector((state) => state.country);

  const [userError, setUserError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCountries());
  }, [dispatch]);

  const doSaveUserData = (event, data) => {
    event.stopPropagation();
    setGlobalEvent({ type: "doStoreUserData", data: data });
  };

  const collectData = (data, e) => {
    let collectedData = {};
    collectedData["user"] = {};

    collectedData["user"]["firstName"] = data.firstName;
    collectedData["user"]["lastName"] = data.lastName;
    collectedData["user"]["yearOfBirth"] = data.yearOfBirth;
    collectedData["user"]["phone"] = phone?.replace(/\s/g, "");
    collectedData["user"]["address"] = {};
    collectedData["user"]["address"]["country"] = country.current;
    collectedData["user"]["address"]["postalCode"] = data.postalCode;
    collectedData["user"]["address"]["city"] = data.city;

    return collectedData;
  };

  const setData = (data, e) => {
    setYearOfBirth(data?.yearOfBirth ? data?.yearOfBirth : "1970");
    setEmail(data?.email ? data?.email : null);
    setFirstName(data?.firstName ? data?.firstName : null);
    setLastName(data?.lastName ? data?.lastName : null);
    setPhone(data?.phone ? data?.phone : "+36");
    country.current = data?.address?.country ? data?.address?.country : "HU";
    setCity(data?.address?.city ? data?.address?.city : "  ");
    setPostalCode(data?.address?.postalCode ? data?.address?.postalCode : null);
    initialData.current = {
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      yearOfBirth: data?.yearOfBirth || "",
      phone: data?.phone?.replace(/\s/g, "") || "",
      address: {
        country: data?.address?.country || "HU",
        postalCode: data?.address?.postalCode || "",
        city: data?.address?.city || "",
      },
    };
  };

  useEffect(() => {
    setData(globalEvent?.data.user);
  }, [location]);

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      //console.log("------ Global Event ------ UserData");
      //console.log(globalEvent);
      //console.log("------------");
    }

    if (globalEvent?.type == "responseGetUser") {
      setData(globalEvent?.data.user);
      //console.log(globalEvent?.data.user);
    }

    if (globalEvent?.type == "responseGetTempUser") {
      setData(globalEvent?.data.user);
      //console.log(globalEvent?.data.user);
    }

    if (globalEvent?.type == "responseGetUserError") {
      localStorage.removeItem("token");
      setUserError(true);
      setGlobalEvent({ type: "goHome", data: {} });
    }
  }, [globalEvent]);

  useEffect(() => {
    setValue("firstName", firstName);
  }, [firstName]);
  useEffect(() => {
    setValue("lastName", lastName);
  }, [lastName]);
  useEffect(() => {
    setValue("postalCode", postalCode);
  }, [postalCode]);
  useEffect(() => {
    setValue("city", city);
  }, [city]);
  useEffect(() => {
    setValue("yearOfBirth", yearOfBirth);
  }, [yearOfBirth]);
  useEffect(() => {
    setValue("phone", phone);
  }, [phone]);
  return (
    <Box sx={{}}>
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          {ReactHtmlParser(t("user.personalData"))}
        </Typography>
        <Typography variant="body3" display={{ xs: "none", sm: "block" }}>
          {ReactHtmlParser(t("user.personalDataText"))}
        </Typography>
      </Box>

      <Box
        sx={{
          mt: 3,
        }}
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit, onError)}>
          <div>
            <TextField
              key="lastName"
              type="text"
              id="outlined-required"
              label={ReactHtmlParser(t("field.lastName"))}
              sx={{
                mt: 2,
                width: "100%",
                "& .MuiInputBase-root": { borderRadius: "50px" },
                ".MuiFormLabel-filled": {
                  top: 12,
                },
                ".MuiInputLabel-root": {
                  top: 12,
                },
              }}
              InputLabelProps={{ shrink: true }}
              InputProps={{ notched: false, maxLength: 64 }}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              {...register("lastName", {
                required: t("errors.field.required"),
                maxLength: {
                  value: 64,
                  message: t("errors.field.maxlength"),
                },

                /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
              })}
            />
            {errors.lastName && (
              <span role="alert">{errors.lastName.message}</span>
            )}

            <TextField
              key="firstName"
              type="text"
              id="outlined-required"
              label={ReactHtmlParser(t("field.firstName"))}
              sx={{
                my: 2,
                width: "100%",
                "& .MuiInputBase-root": { borderRadius: "50px" },
                ".MuiFormLabel-filled": {
                  top: 12,
                },
                ".MuiInputLabel-root": {
                  top: 12,
                },
              }}
              InputLabelProps={{ shrink: true }}
              InputProps={{ notched: false, maxLength: 64 }}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              {...register("firstName", {
                required: t("errors.field.required"),
                maxLength: {
                  value: 64,
                  message: t("errors.field.maxlength"),
                },
                /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
              })}
            />
            {errors.firstName && (
              <span role="alert">{errors.firstName.message}</span>
            )}

            <Controller
              {...register("yearOfBirth", {
                required: t("errors.field.required"),
              })}
              name="yearOfBirth"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      {...field}
                      views={["year"]}
                      label={ReactHtmlParser(t("field.yearOfBirth"))}
                      sx={{
                        width: 187,
                        "& .MuiInputBase-root": { borderRadius: "50px" },
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ notched: false }}
                      format="YYYY"
                      onChange={(newValue) => {
                        //console.log(newValue);
                        var date = new Date(newValue.$d);
                        var year = date.getFullYear();
                        setYearOfBirth(year + "");
                      }}
                      value={dayjs(yearOfBirth ? yearOfBirth : null)}
                      maxDate={dayjs().year(currentYear)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              )}
            />
            {errors.yearOfBirth && (
              <span role="alert">{errors.yearOfBirth.message}</span>
            )}

            <CountrySelect
              register={register}
              errors={errors}
              countries={countries}
              defaultValue={country.current}
              onCountryChanged={handleCountryChange}
              width="280px"
            />

            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              sx={{ mt: 1, mb: 2 }}
            >
              <div>
                <TextField
                  onChange={(event) => {
                    setPostalCode(event.target.value);
                  }}
                  {...register("postalCode", {
                    required: t("errors.field.required"),
                    validate: {
                      validPostalCode: (value) => {
                        if (country.current === "HU") {
                          return /^(?:\d{4})$/.test(value) || t("errors.field.invalidPostalCode");
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ notched: false }}
                  className="asset-field-small"
                  id="outlined-required"
                  label={ReactHtmlParser(t("field.postalCode"))}
                  sx={{
                    display: "block",

                    "& .MuiInputBase-root": { borderRadius: "50px" },
                    ".MuiFormLabel-filled": {
                      top: 12,
                    },
                    ".MuiInputLabel-root": {
                      top: 12,
                    },
                  }}
                />
                {errors.postalCode && (
                  <span role="alert">{errors.postalCode.message}</span>
                )}
              </div>

              {/*<div>
                <TextField
                  key="city"
                  className="asset-field-small"
                  label={ReactHtmlParser(t("field.city"))}
                  type="text"
                  onChange={(event) => {
                    setCity(event.target.value);
                  }}
                  {...register("city", {

                    required: t('errors.field.required'),
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },
                  })}
                />

                {errors.city && <span role="alert">{errors.city.message}</span>}
              </div>*/}
            </Stack>

            <Controller
              name="phone"
              control={control}
              rules={{ validate: (value) => matchIsValidTel(phone) }}
              {...register("phone", {
                required: t("errors.field.required"),
              })}
              render={({
                field: { ref: fieldRef, value, ...fieldProps },
                fieldState,
              }) => (
                <>
                  <Stack direction={"column"} spacing={2}>
                    <MuiTelInput
                      {...fieldProps}
                      value={phone ?? ""}
                      onChange={handlePhoneChange}
                      helperText={
                        matchIsValidTel(phone)
                          ? ""
                          : ReactHtmlParser(t("errors.field.invalidphone"))
                      }
                      error={fieldState.invalid}
                      inputRef={fieldRef}
                      sx={{
                        mt: 3,
                        width: 280,
                        color: "primary.main",
                        fontWeight: 600,
                        fontFamily: "Sora, sans-serif",
                        fontSize: "14px",
                        lineHeight: "22px",
                        borderRadius: "50px",
                        "& .MuiInputBase-root": {
                          borderRadius: "50px",
                          paddingTop: "0 !important",
                          height: "56px",
                        },
                      }}
                    />

                    {errors.phone && (
                      <span role="alert">{errors.phone.message}</span>
                    )}
                  </Stack>
                </>
              )}
            />

            <Stack
              direction={{ xs: "column-reverse", md: "row" }}
              spacing={2}
              sx={{
                mt: 4,
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {isBackBtnVisible && (
              <Button
                variant="text"
                className="secondary-blue-text-btn"
                sx={{ lineHeight: "20.16px" }}
                onClick={() => {
                  if (initialData?.current?.firstName != "") {
                    navigate("/profile", { replace: true });
                  } else {
                    navigate("/", { replace: true });
                  }
                }}
              >
                {ReactHtmlParser(t("common.buttondocancel"))}
              </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                className="secondary-blue-btn"
                sx={{
                  lineHeight: "20.16px",
                  my: 3,
                  py: 2,
                  px: 4,
                  width: { xs: "100%", md: "auto" },
                }}
                endIcon={
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                      fill="#62C275"
                    />
                  </svg>
                }
              >
                {ReactHtmlParser(t("common.buttondonext"))}
              </Button>
            </Stack>
          </div>
        </Box>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
            mb: 6,
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.95 19C16.7184 20.1411 15.7095 21 14.5 21H5.5C4.11929 21 3 19.8807 3 18.5V11.5C3 10.2905 3.85888 9.28164 5 9.05001V8C5 5.23858 7.23858 3 10 3C12.7614 3 15 5.23858 15 8V9.05001C16.1411 9.28164 17 10.2905 17 11.5V13H18.5C19.8807 13 21 14.1193 21 15.5V16.5C21 17.8807 19.8807 19 18.5 19H16.95ZM15.9146 19H11.5C10.1193 19 9 17.8807 9 16.5V15.5C9 14.1193 10.1193 13 11.5 13H16V11.5C16 10.7105 15.39 10.0634 14.6157 10.0044C14.5785 10.0132 14.5398 10.0178 14.5 10.0178C14.454 10.0178 14.4095 10.0116 14.3672 10H5.5C4.67157 10 4 10.6716 4 11.5V18.5C4 19.3284 4.67157 20 5.5 20H14.5C15.1531 20 15.7087 19.5826 15.9146 19ZM14 9H6V8C6 5.79086 7.79086 4 10 4C12.2091 4 14 5.79086 14 8V9ZM20 16.5V15.5C20 14.6716 19.3284 14 18.5 14H11.5C10.6716 14 10 14.6716 10 15.5V16.5C10 17.3284 10.6716 18 11.5 18H18.5C19.3284 18 20 17.3284 20 16.5ZM12.5 15H11.5C11.2239 15 11 15.2239 11 15.5V16.5C11 16.7761 11.2239 17 11.5 17H12.5C12.7761 17 13 16.7761 13 16.5V15.5C13 15.2239 12.7761 15 12.5 15ZM15.5 15C15.7761 15 16 15.2239 16 15.5V16.5C16 16.7761 15.7761 17 15.5 17H14.5C14.2239 17 14 16.7761 14 16.5V15.5C14 15.2239 14.2239 15 14.5 15H15.5ZM18.5 15H17.5C17.2239 15 17 15.2239 17 15.5V16.5C17 16.7761 17.2239 17 17.5 17H18.5C18.7761 17 19 16.7761 19 16.5V15.5C19 15.2239 18.7761 15 18.5 15Z"
              fill="#BBBBBB"
            />
          </svg>
          <Typography variant="caption">
            {ReactHtmlParser(t("common.labelsecurestorage"))}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
